import { useParams } from 'react-router-dom';

export const DiagnosticsComponent = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  return (
    <div>
      <h1>Diagnostics</h1>
      <p>Session ID: {sessionId}</p>
    </div>
  );
};

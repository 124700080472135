import React from 'react';
import Auth from '@aws-amplify/auth';
import { awsConfig } from './config/aws.config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  AuthUserProvider,
  PMSIntegrationUserProvider,
} from './contexts/AuthUserContext';
import { axiosSetup } from './fetchAPI/axios.setup';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import PmsIntegration from './PmsIntegration';
import { ComponentsRoot } from './ComponentsRoot';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment:
      process.env.REACT_APP_SENTRY_ENVIRONMENT || 'unknown_environment',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_ENTRYPOINT === 'components') {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<ComponentsRoot />);
} else {
  Auth.configure({
    region: awsConfig.REGION,
    userPoolId: awsConfig.USER_POOL_ID,
    userPoolWebClientId: awsConfig.USER_POOL_CLIENT_ID,

    // Cognito Hosted UI configuration
    oauth: {
      domain: awsConfig.IDP_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: awsConfig.REDIRECT_SIGN_IN,
      redirectSignOut: awsConfig.REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  });

  axiosSetup();

  const container = document.getElementById('root');
  const root = createRoot(container!);
  if (/^\/pms/.test(window.location.pathname)) {
    root.render(
      <PMSIntegrationUserProvider>
        <PmsIntegration />
      </PMSIntegrationUserProvider>
    );
  } else {
    root.render(
      // Unfortunately Material-ui doesn't support strict mode. The promise is it will work in release v5
      // <React.StrictMode>
      <AuthUserProvider>
        <App />
      </AuthUserProvider>
      // </React.StrictMode>,
    );
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DiagnosticsComponent } from './components-components/DiagnosticsComponent';

export const ComponentsRoot = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/components/diagnostics/:sessionId"
          element={<DiagnosticsComponent />}
        />
      </Routes>
    </BrowserRouter>
  );
};

import React, { useEffect, useReducer, useState } from 'react';
import {
  useVeterinarian,
  useVeterinarianDispatch,
} from '../contexts/VeterinarianContext';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useRequestData } from '../hooks/useRequestData';
import { VetProfile } from '../types/Profile.types';
import { updateProfileReducer } from '../reducers/profile/profile.reducer';
import { DefaultUpdateProfileState } from '../reducers/profile/profile.state';
import { OpenInNew } from '@mui/icons-material';
import Markdown from 'markdown-to-jsx';

export const EULA_UPDATED_AT = '2025-03-02T23:59:59.999Z';
const EULA_MARKETING_TEXT_MARKDOWN = `### Exciting News: GekkoVet Now Supports PDF Uploads!

We're thrilled to introduce a brand-new feature that makes your diagnostic process even more efficient! You can now **upload PDFs** directly into GekkoVet, such as recent laboratory results.`;

const EULAModal = () => {
  const { veterinarian, isLoading } = useVeterinarian();

  const [EULAAcceptedAt, setEULAAcceptedAt] = useState<string | null>(null);
  const [reloadVeterinarian, setReloadVeterinarian] = useState(false);

  const [{ isUpdatingProfile }, dispatchUpdateProfile] = useReducer(
    updateProfileReducer,
    DefaultUpdateProfileState
  );

  useEffect(() => {
    if (isUpdatingProfile) return;
    setEULAAcceptedAt(null);
  }, [isUpdatingProfile]);

  useEffect(() => {
    if (isUpdatingProfile) return;
    setReloadVeterinarian(true);
  }, [isUpdatingProfile]);

  useEffect(() => {
    if (!isLoading) return;
    setReloadVeterinarian(false);
  }, [isLoading]);

  const dispatchVeterinarian = useVeterinarianDispatch();

  useRequestData<VetProfile[]>({
    needTransport: reloadVeterinarian,
    dispatch: dispatchVeterinarian,
    method: 'get',
    params: { modelName: 'Veterinarians' },
  });

  useRequestData<VetProfile[]>({
    needTransport:
      !!EULAAcceptedAt &&
      !isUpdatingProfile &&
      EULAAcceptedAt !== veterinarian?.WhatsNewSeen,
    dispatch: dispatchUpdateProfile,
    method: 'post',
    url: '/profile',
    data: { profile: { ...veterinarian, EULAAcceptedAt } },
    dispatchOptions: {
      dispatch: dispatchVeterinarian,
      veterinarian: veterinarian,
    },
  });

  if (!veterinarian) return null;
  if (isUpdatingProfile) return null;
  if (isLoading) return null;
  if (moment(veterinarian.EULAAcceptedAt).isAfter(EULA_UPDATED_AT)) return null;

  return (
    <Dialog
      open={EULAAcceptedAt === null}
      onClose={() => setEULAAcceptedAt(moment().format())}
    >
      <DialogContent>
        <Typography variant="body1">
          <Markdown>{EULA_MARKETING_TEXT_MARKDOWN}</Markdown>
        </Typography>
        <Typography variant="body2">
          If you want to start using the feature right away, please read and
          accept our updated End User License Agreement (EULA). Otherwise, click
          Cancel and keep using the old version. The old GekkoVet version will
          be available until{' '}
          {moment(EULA_UPDATED_AT).add(2, 'months').format('MMMM Do, YYYY')}.
        </Typography>
        <Typography variant="body2">
          You can find the updated EULA{' '}
          <a
            href="https://www.gekkovet.com/gekkovet-eula"
            target="_blank"
            rel="noreferrer"
          >
            here <OpenInNew style={{ fontSize: '80%' }} />
          </a>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEULAAcceptedAt('')}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            setEULAAcceptedAt(
              moment
                .max(moment(), moment(EULA_UPDATED_AT).add(1, 'seconds'))
                .format()
            )
          }
        >
          {'Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EULAModal;

import { AnimalType } from './AnimalType.type';
import { Country } from './country.type';
import { IdAndName } from './DrugDosages.type';
import {
  validateCountry,
  validateName,
  validatePersonType,
  validateVatId,
} from '../utils/validators';
import { FormData } from './form.type';
import { formatDbDateStringToUi } from '../utils/dateUtils';
import { LOCALE_US } from '../config/constant.params';

export type Specialities = IdAndName;

export type VetProfile = {
  AddedDate: string;
  AdditionalInfo: string;
  Address: string;
  City: string;
  CountryId: number;
  PersonTypeId: number | null;
  Email: string;
  FirstName: string;
  Id: number;
  IsPublic: number;
  Name: string;
  Phone: string;
  PostalCode: string;
  TermsOfServiceIsAccepted: number;
  VetNumber: string;
  VATId: string;
  Entrepreneur: boolean;
  State: string;
  WhatsNewSeen: string;
  UIStorage: string;
  EULAAcceptedAt: string;
};

export type VetProfileWithExpertise = VetProfile & {
  AnimalTypes: AnimalType[];
  Specialities: Specialities[];
};

export type ProfileViewProps = {
  specialities: Specialities[];
  animalTypes: AnimalType[];
  countryList: Country[];
  savingSuccessFull: boolean;
};

export const profileBaseFieldNames = [
  'FirstName',
  'Name',
  'Email',
  'AddedDate',
  'PersonType',
  'Country',
];

export const profileEntrepreneurFieldNames = [
  'VATId',
  'Address',
  'City',
  'PostalCode',
  'State',
];

export type ProfileBaseFieldName = (typeof profileBaseFieldNames)[number];

export type ProfileEntrepreneurFieldName =
  (typeof profileEntrepreneurFieldNames)[number];

export type ProfileFieldType = 'text' | 'country' | 'personType';

export const profileBaseFormData = (
  vetProfile?: VetProfile,
  locale?: string
): FormData<ProfileBaseFieldName, ProfileFieldType> => {
  return {
    FirstName: {
      type: 'text',
      label: 'First Name',
      value: vetProfile ? vetProfile.FirstName : '',
      required: true,
      validator: validateName,
    },
    Name: {
      type: 'text',
      label: 'Last Name',
      value: vetProfile ? vetProfile.Name : '',
      required: true,
      validator: validateName,
    },
    Email: {
      type: 'text',
      label: 'Email',
      value: vetProfile ? vetProfile.Email : '',
      required: false,
      validator: (f) => f,
      disabled: true,
    },
    Country: {
      type: 'country',
      label: 'Country',
      value: vetProfile ? vetProfile.CountryId.toString() : '',
      required: true,
      validator: validateCountry,
    },
    PersonType: {
      type: 'personType',
      label: 'Role',
      value:
        vetProfile && vetProfile.PersonTypeId
          ? vetProfile.PersonTypeId.toString()
          : '',
      required: true,
      validator: validatePersonType,
    },
    VetNumber: {
      type: 'text',
      label: 'Vet Number',
      value: vetProfile ? vetProfile.VetNumber : '',
    },
    AddedDate: {
      type: 'text',
      label: 'Created at',
      value: vetProfile
        ? formatDbDateStringToUi(vetProfile.AddedDate, locale || LOCALE_US)
        : '',
      disabled: true,
    },
  };
};

export const profileEntrepreneurFormData = (
  vetProfile?: VetProfile
): FormData<ProfileEntrepreneurFieldName, ProfileFieldType> => {
  return {
    VATId: {
      type: 'text',
      label: 'VAT identification number',
      value: vetProfile ? vetProfile.VATId : '',
      required: true,
      helperText:
        'For VAT purposes we ask you kindly to enter your VAT identification number, your business id or tax number',
      validator: validateVatId,
      error: false,
    },
    Address: {
      type: 'text',
      label: 'Address',
      value: vetProfile ? vetProfile.Address : '',
      required: true,
      validator: validateVatId,
      error: false,
    },
    PostalCode: {
      type: 'text',
      label: 'Zip/Postal code',
      value: vetProfile ? vetProfile.PostalCode : '',
      required: true,
      validator: validateVatId,
      error: false,
    },
    City: {
      type: 'text',
      label: 'City',
      value: vetProfile ? vetProfile.City : '',
      required: true,
      validator: validateVatId,
      error: false,
    },
    State: {
      type: 'text',
      label: 'State',
      value: vetProfile ? vetProfile.State : '',
      required: vetProfile
        ? vetProfile.CountryId === 233
          ? true
          : false
        : false,
      error: false,
      validator: vetProfile
        ? vetProfile.CountryId === 233
          ? validateVatId
          : undefined
        : undefined,
    },
  };
};

export type TExpertiseAction =
  | 'setSelectedAnimalType'
  | 'setSelectedSpeciality';

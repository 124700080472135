import React, { useEffect, useMemo, useState } from 'react';
import {
  ButtonGroup,
  CardProps,
  Container,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import { Card } from '../common/Card';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { useLLMDiagnose } from '../../contexts/LLMDiagnoseContext';
import { AttachFile, AutoAwesome } from '@mui/icons-material';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import { usePatientInfoDispatch } from '../../contexts/CurrentPatientInfoContext';
import { useRWDContext } from '../../contexts/RWDContext';
import Loader from '../Loader';

import API from '../../api/ApiLayer';
import { useVeterinarian } from '../../contexts/VeterinarianContext';
import moment from 'moment';
import { FileUploader } from 'react-drag-drop-files';
import { EULA_UPDATED_AT } from '../EULAModal';

const useStyles = makeStyles()((theme: Theme) => ({
  continueButton: {
    marginTop: theme.spacing(2),
  },
  infoTextAlignment: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  containerText: {},
  fileUploader: {
    border: `dashed 2px ${theme.palette.primary.main} !important`,
    display: 'flex',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

export default function LLMDiagnose({ style }: CardProps) {
  const { classes } = useStyles();
  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const [fileProcessing, setFileProcessing] = useState(false);
  const [threadAndRunId, setThreadAndRunId] = useState('');
  const [processTimer, setProcessTimer] = useState<NodeJS.Timeout | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const { veterinarian } = useVeterinarian();

  const allowUpload = useMemo(() => {
    if (!veterinarian) return false;
    return moment(veterinarian.EULAAcceptedAt).isAfter(EULA_UPDATED_AT);
  }, [veterinarian]);

  const {
    freeText,
    setFreeText,
    setExtractedSymptoms,
    setExtractedAnimal,
    setIsActiveLLMDiagnoseFlow,
  } = useLLMDiagnose();
  // clear the free text when the component is mounted
  useEffect(() => {
    setFreeText('');
  }, [setFreeText]);
  // clear selected symptoms upon mount
  useEffect(() => {
    setExtractedSymptoms({});
    setExtractedAnimal(null);
  }, []); // eslint-disable-line
  // clear active llm flow
  useEffect(() => {
    setIsActiveLLMDiagnoseFlow(false);
  }, [setIsActiveLLMDiagnoseFlow]);
  // on init, reset all diagnose data
  useEffect(() => {
    dispatchPageDiagnoses({ type: 'resetAll' });
    dispatchPatientInfo({ type: 'resetPatientInfo' });
  }, []); // eslint-disable-line
  // check if is sm screen
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { track: trackRWD } = useRWDContext();
  const rwd = () => {
    trackRWD({ actionName: 'diagnostics_start_page' });
  };

  useEffect(() => {
    if (attachmentDialogOpen) return;
    setFiles(null);
  }, [attachmentDialogOpen]);

  useEffect(() => {
    if (!threadAndRunId || processTimer)
      return () => {
        if (processTimer) {
          clearInterval(processTimer);
        }
      };
    setProcessTimer(
      setInterval(async () => {
        try {
          const [threadId, runId] = threadAndRunId.split('/');
          const { body } = await API.getRequest<{
            status: string;
            messages: string[];
          }>(`/assistants/result?thread_id=${threadId}&run_id=${runId}`, {});
          if (body!.status !== 'in_progress') {
            clearInterval(processTimer!);
            setProcessTimer(null);
            setFileProcessing(false);
            setThreadAndRunId('');
            if (body!.status === 'completed') {
              setFreeText(
                (freeText ? freeText + '\n\n' : '') + body!.messages[0]
              );
            } else {
              setFreeText(
                (freeText ? freeText + '\n\n' : '') +
                  'Error processing attachment'
              );
            }
          }
        } catch (e) {
          console.error('Error checking attachment status', e);
        }
      }, 2000)
    );
    return () => {
      if (processTimer) {
        clearInterval(processTimer);
      }
    };
  }, [threadAndRunId, processTimer, setFreeText, freeText]);

  const uploadAttachment = async (file: File) => {
    const resp = await API.postRequest<{ url: string; filename: string }>(
      '/assistants/attachment',
      { type: 'application/pdf', name: file!.name }
    );
    if (!resp.body) {
      throw new Error('No response from the server');
    }
    const { url, filename } = resp.body; // S3 url
    const uploadResp = await fetch(url, {
      method: 'PUT',
      body: file!,
    });
    if (!uploadResp.ok) {
      throw new Error('Error uploading attachment');
    }
    return filename;
  };

  const uploadAttachments = async () => {
    setFileProcessing(true);
    setAttachmentDialogOpen(false);
    const filenames = await Promise.all(
      Array.from(files!).map(uploadAttachment)
    );
    try {
      const { body } = await API.postRequest<{
        thread_id: string;
        run_id: string;
      }>('/assistants/request', {
        filenames,
      });
      setThreadAndRunId(`${body!.thread_id}/${body!.run_id}`);
    } catch (e) {
      console.error('Error uploading attachment', e);
      setFileProcessing(false);
      setFreeText(
        (freeText ? freeText + '\n\n' : '') + 'Error processing attachment'
      );
    }
  };

  const handleFiles = (newFiles: FileList) => {
    const list = Array.from(files || []);
    Array.from(newFiles).forEach((file) => {
      list.push(file);
    });
    setFiles(list);
    setAttachmentDialogOpen(true);
  };

  return (
    <Card
      title="Diagnostics"
      pageView="noPadding"
      withoutMarginBottom
      headerCentered
      style={style}
    >
      <Grid container style={{ height: '100%' }}>
        <Grid item className={classes.infoTextAlignment} xs={12}>
          <Container
            fixed
            className={classes.containerText}
            style={{ position: 'relative' }}
          >
            <Typography align="center">
              <AutoAwesome /> Describe the patient and your findings to start
              diagnosing
            </Typography>

            {/*allowUpload && (
              <Button
                variant="contained"
                color="primary"
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => setAttachmentDialogOpen(true)}
              >
                <AttachFile />
                Attach File
              </Button>
            )*/}
          </Container>
        </Grid>
        <Grid
          xs={12}
          lg={12}
          sm={12}
          md={12}
          item
          flexGrow={'1'}
          style={{ position: 'relative' }}
        >
          <TextField
            multiline
            rows={isSmScreen ? 10 : 15}
            value={freeText}
            style={{ height: '100%' }}
            onChange={(ev) => setFreeText(ev.target.value)}
            placeholder="For example: Bella, 6yo intact female golden retriever, has started vomiting and has diarrhea."
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={9} style={{ paddingTop: '1em' }}>
              {allowUpload && (
                <FileUploader
                  classes={[classes.fileUploader]}
                  multiple
                  handleChange={handleFiles}
                  name="file"
                  types={['PDF']}
                >
                  <AttachFile />
                  Attach files by dragging and dropping here or by clicking this
                  area
                </FileUploader>
              )}
            </Grid>
            <Grid item xs={12} md={3} textAlign="right">
              <ButtonGroup className={classes.continueButton}>
                {/*}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAttachmentDialogOpen(true)}
                >
                  <Attachment />
                </Button>*/}
                <Button
                  title="Continue"
                  onClick={rwd}
                  component={Link}
                  to="/work/patient/test/visit/test/diagnoses"
                  color="primary"
                  variant="contained"
                >
                  Continue
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={attachmentDialogOpen}
        onClose={() => setAttachmentDialogOpen(false)}
      >
        <DialogTitle>Attachment</DialogTitle>
        <DialogContent>
          <Typography>
            You can attach files like lab results to help with the diagnosis.
          </Typography>
          <Typography>
            The files will be processed and appended to the rest of your text.
          </Typography>
          {/*<FormControl>
            {!file && (
              <Button
                variant="contained"
                color="primary"
                component="label"
                style={{ marginTop: '1em' }}
              >
                Attach File
                <input
                  type="file"
                  hidden
                  accept="application/pdf"
                  onChange={(ev) => setFile(ev.target.files![0])}
                />
              </Button>
            )}
            {file && (
              <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography>File: {file?.name}</Typography>
              </Box>
            )}
          </FormControl>*/}
          <Box sx={{ marginTop: 1, marginBottom: 1 }}>
            {files && (
              <ul>
                {Array.from(files).map((file) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            )}
          </Box>
          <FileUploader
            classes={[classes.fileUploader]}
            handleChange={handleFiles}
            multiple
            types={['PDF']}
          >
            <AttachFile />
            Attach more files by dragging and dropping here or by clicking this
            area
          </FileUploader>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttachmentDialogOpen(false)}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!files}
            onClick={uploadAttachments}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Loader showLoader={fileProcessing} />
    </Card>
  );
}
